import { BadgeColor } from '@swe/shared/ui-kit/components/badge';
import { ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';
import Stack from '@swe/shared/ui-kit/components/stack';

import { DocumentVerificationImpl } from 'common/entities/documents/DocumentVerification';
import { DocumentListSkeleton } from 'domains/profile/containers/documents/components/document-list/skeleton';
import { VerificationStatusId } from 'entities/documents/documents-v2';

const getDocumentBadgeColor = (verification: DocumentVerificationImpl): BadgeColor => {
  switch (verification.data.verificationStatus.id) {
    case VerificationStatusId.None:
      return 'light';
    case VerificationStatusId.Verified:
      return 'success';
    case VerificationStatusId.PendingVerification:
      return 'warning';
    case VerificationStatusId.UserActionRequired:
    case VerificationStatusId.DeclinedByManager:
    case VerificationStatusId.UserAbandonedVerification:
      return 'danger';
  }
};

const getDocumentName = (verification: DocumentVerificationImpl) => {
  const {
    verificationDocumentType: { name },
    maskedDocumentNumber,
  } = verification.data;

  return maskedDocumentNumber ? name.concat(` ${maskedDocumentNumber}`) : name;
};

type DocumentListProps = {
  documents: DocumentVerificationImpl[];
  onDocumentClick?: (verification: DocumentVerificationImpl) => void;
};

const DocumentList = ({ documents, onDocumentClick }: DocumentListProps) => {
  if (documents.length === 0) return null;

  return (
    <Stack
      divider
      spacing="none"
    >
      {documents.map((verification) => (
        <SectionHeading
          key={verification.id}
          size="sm"
          badge={{ children: verification.data.verificationStatus.name, color: getDocumentBadgeColor(verification) }}
          onClick={verification.isActionRequired ? () => onDocumentClick?.(verification) : undefined}
          trailIcon={verification.isActionRequired ? ChevronRightIcon : undefined}
          badgeStopPropagation={false}
        >
          {getDocumentName(verification)}
        </SectionHeading>
      ))}
    </Stack>
  );
};

const DocumentListSkeletonized = withSkeleton(DocumentList, DocumentListSkeleton, ['documents']);

export { DocumentList, DocumentListSkeletonized };
