import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { ProfileDocuments } from 'domains/profile/containers/documents';

const TITLE = 'Documents';

const ProfileDocumentsPage: ApplicationPage = () => {
  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{ title: TITLE, defaultBackRoute: Routes.Profile }}
    >
      <ProfileDocuments />
    </Page>
  );
};

ProfileDocumentsPage.getMeta = () => ({ title: TITLE });

ProfileDocumentsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileDocumentsPage;
