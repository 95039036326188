import { ButtonProps } from '@swe/shared/ui-kit/components/button';
import { SectionHeadingProps } from '@swe/shared/ui-kit/components/section-heading';

import { Routes, ShopRouterNavigate } from 'common/router/constants';
import { DocumentCategory, documentCategoryToLabel } from 'entities/documents/documents-v2';

const COMMON_SECTION_PROPS: Partial<SectionHeadingProps> = {
  size: 'md',
};

const getButton = (navigate: ShopRouterNavigate, documentType: DocumentCategory): ButtonProps => ({
  children: 'Upload',
  color: 'light',
  name: 'upload',
  onClick: () => navigate({ pathname: Routes.ProfileAddDocuments, query: { category: documentType } }),
});

const getSections = (
  navigate: ShopRouterNavigate,
  { isMedicalSectionEnabled }: { isMedicalSectionEnabled?: boolean },
): SectionHeadingProps[] => {
  return [
    {
      ...COMMON_SECTION_PROPS,
      children: documentCategoryToLabel(DocumentCategory.Identity),
      subHeading:
        'To verify that you’re legally able to purchase cannabis, we need a copy of your state or federally issued ID',
      button: getButton(navigate, DocumentCategory.Identity),
    },
    isMedicalSectionEnabled && {
      ...COMMON_SECTION_PROPS,
      children: documentCategoryToLabel(DocumentCategory.Medical),
      subHeading:
        'To verify that you’re eligible for medicinal cannabis, we need a copy of your medical card or recommendation',
      button: getButton(navigate, DocumentCategory.Medical),
    },
    {
      ...COMMON_SECTION_PROPS,
      children: documentCategoryToLabel(DocumentCategory.Additional),
      subHeading:
        'Uploading documents like veteran, first responder, or student IDs may give you access to related discount programs',
      button: getButton(navigate, DocumentCategory.Additional),
    },
  ].filter(Boolean) as SectionHeadingProps[];
};

export { getSections, getButton };
