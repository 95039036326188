import { Bone, Skeleton } from '@swe/shared/ui-kit/components/skeleton';

import { useTheme } from '@swe/shared/ui-kit/theme/provider';

import styles from './styles.module.scss';

const DOCUMENT_HEIGHT_PX = 30;
const DIVIDER_HEIGHT_PX = 1;
const DIVIDER_PADDING = 12;
const TOTAL_HEIGHT = DOCUMENT_HEIGHT_PX * 3 + DIVIDER_HEIGHT_PX * 2 + DIVIDER_PADDING * 2 * 2;

const DocumentSkeleton = ({ y }: { y: number }) => {
  const theme = useTheme();

  return (
    <Bone
      x={0}
      y={y}
      rx={theme.borderRadius.scale.sm}
      ry={theme.borderRadius.scale.sm}
      width="100%"
      height={DOCUMENT_HEIGHT_PX}
    />
  );
};

const Divider = ({ y }: { y: number }) => (
  <Bone
    x={0}
    y={y}
    width="100%"
    height={DIVIDER_HEIGHT_PX}
  />
);

const DocumentListSkeleton = () => {
  return (
    <Skeleton
      className={styles.skeleton}
      uniqueKey="profile_document_list"
      height={TOTAL_HEIGHT}
    >
      <DocumentSkeleton y={0} />
      <Divider y={1 * DOCUMENT_HEIGHT_PX + 1 * DIVIDER_PADDING} />
      <DocumentSkeleton y={1 * DOCUMENT_HEIGHT_PX + 1 * DIVIDER_HEIGHT_PX + 2 * DIVIDER_PADDING} />
      <Divider y={2 * DOCUMENT_HEIGHT_PX + 1 * DIVIDER_HEIGHT_PX + 3 * DIVIDER_PADDING} />
      <DocumentSkeleton y={2 * DOCUMENT_HEIGHT_PX + 2 * DIVIDER_HEIGHT_PX + 4 * DIVIDER_PADDING} />
    </Skeleton>
  );
};

export { DocumentListSkeleton };
