import Alert from '@swe/shared/ui-kit/components/alert';
import Button from '@swe/shared/ui-kit/components/button';
import { CartIcon, CheckCircleIcon } from '@swe/shared/ui-kit/components/icon';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { DocumentVerificationImpl } from 'common/entities/documents/DocumentVerification';

import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { useDocumentsPrototypes } from 'common/use-cases/documents/use-documents-prototypes';
import { useDocumentsVerifications } from 'common/use-cases/documents/use-documents-verifications';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { DocumentListSkeletonized } from 'domains/profile/containers/documents/components/document-list';
import { getSections } from 'domains/profile/containers/documents/config';
import { DocumentCategory } from 'entities/documents/documents-v2';

const ProfileDocuments = () => {
  // TODO: ADD TYPES
  const { verifications } = useDocumentsVerifications();
  const { prototypes } = useDocumentsPrototypes();
  const { toCatalog } = useCatalogQuery();
  const navigate = useRouterNavigate();

  const handleDocumentClick = useCallback(
    (verification: DocumentVerificationImpl) => {
      void navigate({
        pathname: Routes.ProfileDocument,
        query: { category: verification.type, verificationId: String(verification.id) },
      });
    },
    [navigate],
  );

  return (
    <Stack spacing="xl">
      <Stack spacing="sm">
        <Alert
          icon={CheckCircleIcon}
          color="neutral"
        >
          You’re good to go! Feel free to start shopping. Uploading documents now will save you some time when you
          arrive at the dispensary
        </Alert>
        <DocumentListSkeletonized
          documents={verifications?.getList()}
          onDocumentClick={handleDocumentClick}
        />
      </Stack>
      <Stack
        divider
        spacing="none"
      >
        {getSections(navigate, {
          isMedicalSectionEnabled: prototypes?.getPrototypes(DocumentCategory.Medical).length !== 0,
        }).map((p, idx) => (
          <SectionHeading
            className={idx === 0 ? styles.section_noPadding : undefined}
            key={idx}
            size="md"
            {...p}
          />
        ))}
      </Stack>
      <Button
        icon={CartIcon}
        onClick={() => toCatalog({ filters: null, searchTerm: null, subPath: null })}
        name="start-shopping"
      >
        Start shopping
      </Button>
    </Stack>
  );
};

export { ProfileDocuments };
